<template>
  <div>
    <div class="image_background">
      <c-header></c-header>
      <div class="presentation">
        <div class="logo" v-for="logo in logos" :key="logo.id">
          <img :src="logo.acf.img_logo.url" alt="le logo de l'association">
        </div>
        <h1>CŒUVATTE-SUARCINE-VENDELINE</h1>
        <p>Les commnunes de l'association</p>
        <div class="fleche">
          <a href="#ancre">
            <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
              <path d="M11 21.883l-6.235-7.527-.765.644 7.521 9 7.479-9-.764-.645-6.236 7.529v-21.884h-1v21.883z"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <main>
      <div id="ancre"></div>
      <div class="degrade">
        <div id="container_decouvrez">
          <div class="decouvrez">
            <div class="svg_decouvrez">
              <svg data-name="Groupe 313" viewBox="0 0 89.235 89.316" xmlns="http://www.w3.org/2000/svg">
                <circle transform="translate(10 10)" cx="35" cy="35" r="35" fill="#d96b2b"/>
                <g transform="translate(0 26.848)">
                  <path transform="translate(-.233 -153.9)"
                        d="M22.132,153.9a21.924,21.924,0,0,0-21.9,21.9c0,7.5,3.566,15.558,10.6,23.958,1.045,1.248,2.087,2.4,3.086,3.46-4.542,1.24-7.268,3.467-7.268,6.066,0,4.6,7.976,7.085,15.48,7.085s15.48-2.483,15.48-7.085c0-2.6-2.726-4.825-7.264-6.065a71.3,71.3,0,0,0,5.615-6.678,1.31,1.31,0,1,0-2.109-1.554,70.319,70.319,0,0,1-6.765,7.816,1.309,1.309,0,0,0-.571.567c-1.891,1.858-3.482,3.237-4.385,3.99-.893-.746-2.46-2.106-4.327-3.94a1.31,1.31,0,0,0-.656-.652c-5.932-5.967-14.3-16.276-14.3-26.968a19.28,19.28,0,0,1,38.559,0c0,4.643-1.582,9.656-4.7,14.9a1.31,1.31,0,1,0,2.251,1.339c3.364-5.656,5.07-11.12,5.07-16.241A21.924,21.924,0,0,0,22.132,153.9Zm-.8,56.183a1.31,1.31,0,0,0,1.6,0A66.571,66.571,0,0,0,28.2,205.4c4.417.9,6.793,2.635,6.793,3.884,0,.932-1.278,2.032-3.335,2.871a26.111,26.111,0,0,1-9.526,1.595c-7.851,0-12.861-2.645-12.861-4.465,0-1.25,2.376-2.984,6.8-3.885A66.559,66.559,0,0,0,21.33,210.085Z"/>
                </g>
                <g transform="translate(12.378 39.225)">
                  <path transform="translate(-71.187 -224.85)"
                        d="m80.708 224.85a9.444 9.444 0 0 0-5.7 1.891 1.31 1.31 0 1 0 1.568 2.1 6.884 6.884 0 1 1-2.507 3.624 1.31 1.31 0 1 0-2.518-0.723 9.523 9.523 0 1 0 9.153-6.891z"/>
                </g>
                <g transform="translate(54.967)">
                  <path transform="translate(-315.33)"
                        d="M339.336,38.05c.666-.715,1.353-1.485,2.041-2.308,5.453-6.513,8.218-12.773,8.218-18.609a17.136,17.136,0,0,0-32.921-6.67,1.31,1.31,0,0,0,2.412,1.021,14.517,14.517,0,0,1,27.889,5.649c0,10.823-11.363,21.192-14.515,23.86-.695-.587-1.788-1.548-3.07-2.8a1.308,1.308,0,0,0-.582-.579c-4.522-4.553-10.861-12.38-10.861-20.476a1.31,1.31,0,0,0-2.619,0c0,5.835,2.765,12.1,8.218,18.609.688.822,1.374,1.592,2.04,2.307-3.376,1.014-5.325,2.741-5.325,4.82,0,1.754,1.422,3.3,4.006,4.351a24.742,24.742,0,0,0,16.391,0c2.583-1.053,4.005-2.6,4.005-4.352C344.661,40.794,342.711,39.066,339.336,38.05ZM332.461,46c-6.2,0-9.582-2.067-9.582-3.129,0-.692,1.538-1.958,4.824-2.656a51.178,51.178,0,0,0,3.957,3.511,1.31,1.31,0,0,0,1.6,0,51.118,51.118,0,0,0,3.957-3.511,12.005,12.005,0,0,1,3.619,1.309c.754.459,1.2.963,1.2,1.347C342.042,43.931,338.66,46,332.461,46Z"/>
                </g>
                <g transform="translate(64.48 9.513)">
                  <path transform="translate(-369.86 -54.532)"
                        d="m377.48 54.532a7.621 7.621 0 1 0 7.621 7.621 7.63 7.63 0 0 0-7.621-7.621zm0 12.623a5 5 0 1 1 5-5 5.007 5.007 0 0 1-5 5z"/>
                </g>
                <g transform="translate(52.075 41.56)">
                  <path transform="translate(-298.75 -238.24)"
                        d="M301.789,238.239h-.6a9.881,9.881,0,0,0-1.3.086,1.31,1.31,0,1,0,.346,2.6,7.283,7.283,0,0,1,.956-.063h.6a1.31,1.31,0,0,0,0-2.62Z"/>
                </g>
                <g transform="translate(47.742 80.855)">
                  <path transform="translate(-273.91 -463.5)"
                        d="M276.951,463.5h-.006l-1.73.008a1.31,1.31,0,0,0,.006,2.619h.006l1.73-.008a1.31,1.31,0,0,0-.006-2.619Z"/>
                </g>
                <g transform="translate(53.626 58.525)">
                  <path transform="translate(-307.64 -335.49)"
                        d="m310.68 335.49h-1.73a1.31 1.31 0 1 0 0 2.619h1.73a1.31 1.31 0 1 0 0-2.619z"/>
                </g>
                <g transform="translate(46.309 43.807)">
                  <path transform="translate(-265.7 -251.12)"
                        d="m269.11 251.54a1.31 1.31 0 0 0-1.851-0.075 9.844 9.844 0 0 0-1.32 1.492 1.31 1.31 0 1 0 2.128 1.528 7.239 7.239 0 0 1 0.968-1.095 1.31 1.31 0 0 0 0.075-1.85z"/>
                </g>
                <g transform="translate(54.662 80.823)">
                  <path transform="translate(-313.58 -463.31)"
                        d="m316.62 463.31h-6e-3l-1.73 8e-3a1.31 1.31 0 0 0 6e-3 2.619h6e-3l1.73-8e-3a1.31 1.31 0 0 0-6e-3 -2.619z"/>
                </g>
                <g transform="translate(40.822 80.886)">
                  <path transform="translate(-234.25 -463.68)"
                        d="m237.29 463.68h-6e-3l-1.73 8e-3a1.31 1.31 0 0 0 6e-3 2.62h6e-3l1.73-8e-3a1.31 1.31 0 0 0-6e-3 -2.619z"/>
                </g>
                <g transform="translate(47.325 56.157)">
                  <path transform="translate(-271.52 -321.92)"
                        d="m274.89 323.18a7.228 7.228 0 0 1-1.15-0.9 1.31 1.31 0 1 0-1.816 1.888 9.845 9.845 0 0 0 1.568 1.228 1.31 1.31 0 1 0 1.4-2.215z"/>
                </g>
                <g transform="translate(44.722 49.954)">
                  <path transform="translate(-256.6 -286.36)"
                        d="M259.349,289.139a7.238,7.238,0,0,1-.133-1.381v-.073a1.309,1.309,0,0,0-1.294-1.325h-.016a1.31,1.31,0,0,0-1.309,1.295v.1a9.861,9.861,0,0,0,.181,1.882,1.31,1.31,0,0,0,2.571-.5Z"/>
                </g>
                <g transform="translate(60.546 58.525)">
                  <path transform="translate(-347.31 -335.49)"
                        d="m350.35 335.49h-1.73a1.31 1.31 0 1 0 0 2.619h1.73a1.31 1.31 0 1 0 0-2.619z"/>
                </g>
                <g transform="translate(81.057 59.947)">
                  <path transform="translate(-464.89 -343.64)"
                        d="m468.44 344.89a12.471 12.471 0 0 0-1.6-1.075 1.31 1.31 0 0 0-1.286 2.282 9.878 9.878 0 0 1 1.266 0.849 1.31 1.31 0 0 0 1.624-2.056z"/>
                </g>
                <g transform="translate(75.42 80.631)">
                  <path transform="translate(-432.58 -462.21)"
                        d="m436.91 463.34a1.31 1.31 0 0 0-1.477-1.118 9.852 9.852 0 0 1-1.306 0.092h-0.245a1.31 1.31 0 0 0 6e-3 2.619h0.251a12.5 12.5 0 0 0 1.653-0.117 1.31 1.31 0 0 0 1.118-1.476z"/>
                </g>
                <g transform="translate(81.934 77.672)">
                  <path transform="translate(-469.92 -445.25)"
                        d="m473.52 445.66a1.309 1.309 0 0 0-1.852-0.045 9.9 9.9 0 0 1-1.182 0.964 1.31 1.31 0 1 0 1.492 2.153 12.53 12.53 0 0 0 1.5-1.22 1.31 1.31 0 0 0 0.042-1.852z"/>
                </g>
                <g transform="translate(86.077 71.695)">
                  <path transform="translate(-493.67 -410.99)"
                        d="m495.67 411.01a1.31 1.31 0 0 0-1.529 1.045 9.809 9.809 0 0 1-0.4 1.47 1.31 1.31 0 1 0 2.467 0.88 12.413 12.413 0 0 0 0.5-1.865 1.31 1.31 0 0 0-1.038-1.53z"/>
                </g>
                <g transform="translate(85.684 64.94)">
                  <path transform="translate(-491.41 -372.26)"
                        d="M494.587,374.832a12.367,12.367,0,0,0-.678-1.811,1.31,1.31,0,1,0-2.374,1.108,9.734,9.734,0,0,1,.534,1.426,1.31,1.31,0,1,0,2.518-.723Z"/>
                </g>
                <g transform="translate(67.465 58.525)">
                  <path transform="translate(-386.98 -335.49)"
                        d="m390.02 335.49h-1.73a1.31 1.31 0 0 0 0 2.619h1.73a1.31 1.31 0 0 0 0-2.619z"/>
                </g>
                <g transform="translate(61.581 80.792)">
                  <path transform="translate(-353.24 -463.13)"
                        d="m356.28 463.13h-6e-3l-1.73 8e-3a1.31 1.31 0 0 0 6e-3 2.619h6e-3l1.73-8e-3a1.31 1.31 0 0 0-6e-3 -2.619z"/>
                </g>
                <g transform="translate(74.385 58.525)">
                  <path transform="translate(-426.64 -335.49)"
                        d="M429.737,335.5c-.185-.008-.373-.012-.558-.012h-1.23a1.31,1.31,0,0,0,0,2.619h1.23q.222,0,.442.01h.059a1.31,1.31,0,0,0,.057-2.618Z"/>
                </g>
                <g transform="translate(68.5 80.76)">
                  <path transform="translate(-392.91 -462.96)"
                        d="m395.95 462.96h-6e-3l-1.73 8e-3a1.31 1.31 0 0 0 6e-3 2.619h6e-3l1.73-8e-3a1.31 1.31 0 0 0-6e-3 -2.619z"/>
                </g>
              </svg>
            </div>
            <h1>DÉCOUVREZ TOUTES NOS COMMUNES</h1>
            <a :href="'#' + commune.nom_commune" v-for="commune in communes" :key="commune.id">{{ commune.nom_commune }}</a>
          </div>
        </div>

        <div id="container_communes">
          <div :id="commune.nom_commune" v-for="commune in communes" :key="commune.id">
          <div class="img_ville">
            <img :src="commune.image_commune.url" alt="commune.nom_commune">
            </div>
            <h3 id="h_communes">{{ commune.nom_commune }}</h3>
            <p id="communes_texte" v-html="commune.extrait_texte_commune"></p>
            <div class="lire">
              <div class="btn btn-success"><button @click="setRevele(commune.id)">Lire la suite</button></div>
              <modale :show="revele == commune.id" :commune="commune" @leaveModal="resetRevele"></modale>
            </div>
          </div>
        </div>
      </div>
    </main>
    <c-footer></c-footer>
  </div>
</template>
<script>
import axios from 'axios';
import CFooter from '../components/c-footer.vue'
import CHeader from '../components/c-header.vue'
import Modale from '../components/modale.vue';

export default {
  name: 'Communes',
  components: {
    CFooter,
    CHeader,
    modale: Modale,
  },
  data() {
    return {
      revele: -1,
      communes: [],
      logos: [],
    };
  },
  methods: {
    setRevele(id) {
      this.revele = id
    },
    resetRevele() {
      this.revele = -1;
    }
  },
  mounted() {
    axios.get('https://csv-patrimoine.org/wp-json/wp/v2/commune').then((response) => {
      this.communes = response.data;
    });

    axios.get('https://csv-patrimoine.org/wp-json/wp/v2/logo').then((response) => {
      this.logos = response.data;
    });
  },
}

</script>
<style scoped>

@media screen and (max-width: 3000px) {
  .presentation p {
    font-family: "Bebas Neue";
    font-size: 50px;
  }
}

@media screen and (max-width: 768px) {
  .presentation p {
    font-size: 30px;
  }
}
@media screen and (max-width: 480px) {
  .presentation p {
    font-size: 25px;
  }
}
</style>